import qs from 'qs';
import { APIResponseCollection } from './types/strapi';
import type { Common } from '@strapi/strapi';

const baseUrl = process.env.REACT_APP_STRAPI_URL;

export async function getCollection<K extends Common.UID.ContentType>(
  path: string,
  options?: Options
): Promise<APIResponseCollection<K>> {
  return get<APIResponseCollection<K>>(path, options);
}

async function get<T>(
  path: string,
  { filters, sort, pagination, populate }: Options = {}
): Promise<T> {
  if (baseUrl === undefined) {
    throw new Error('REACT_APP_STRAPI_URL is not defined');
  }
  const query = qs.stringify({
    populate: populate ?? '*',
    filters,
    sort,
    pagination,
  });
  const url = `${baseUrl}/api${path}?` + query;

  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      'Strapi-Response-Format': 'v4',
    },
  });
  return (await response.json()) as T;
}

type Options = {
  filters?: Filters;
  sort?: Sort;
  pagination?: Pagination;
  populate?: Populate;
};

type Populate = string | Record<string, any>;

type Filters = Record<string, any>;

type Sort = string;

type Pagination = {
  page?: number;
  pageSize?: number;
  withCount?: boolean;
};
